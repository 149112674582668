import React from "react"
import Button from "@bit/azheng.joshua-tree.button"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TheaterVideo from "@components/Theater/TheaterVideo"
const wisdomTeethData = require("../_archives/procedures/wisdom-teeth-removal.json")

import "../css/pages/w4w-22.scss"

const W4W = () => {
  const image = name =>
    `https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/${name}`
  return (
    <Layout pageTitle="wisdom-page" language={"en"}>
      <SEO
        lang="en"
        title="Learn about our Wisdom For Wisdom program"
        description="Follow the Wisdom For Wisdom steps to apply"
      />
      <div className="w4w-22">
        <div className="w4w-22__hero">
          <img
            src={image("w4w-top-left-corner-image")}
            className="w4w-22__hero-tl w4w-22__shapes-img"
            alt="color shapes"
          />
          <img
            src={image("2022-w4w-logo")}
            className="w4w-22__hero-middle"
            alt="wisdom for wisdom logo"
          />
          <img
            src={image("w4w-top-right-corner-image")}
            className="w4w-22__hero-tr w4w-22__shapes-img"
            alt="colorful shapes"
          />
        </div>

        <div className="w4w-22__about">
          <div className="w4w-22__about-content">
            <h1>Saving for College? We Want to Help!</h1>
            <h2>
              College costs are higher than ever. We’re doing our part to help
              local families save money.
            </h2>
            <p>
              Our 2022 Wisdom For Wisdom program is here! This program helps to
              offset some of the costs of college by providing free wisdom teeth
              removal to two students who live in or near Amarillo.
            </p>
            <p>
              Are you saving money for college and need wisdom teeth removed?
              Submit an application and we might select you to receive a FREE
              wisdom teeth removal procedure. Apply now or share this
              information with a student today!
            </p>
          </div>
        </div>

        <div className="w4w-22__apply">
          <img
            src={image("w4w-mid-left-image")}
            className="w4w-22__apply-tl w4w-22__shapes-img"
            alt="color shapes"
          />
          <div className="w4w-22__apply-content">
            <h2>Thank you for your interest in our Wisdom For Wisdom program. Our 2022 application period is closed, but stay tuned on our <a href="https://www.facebook.com/thirdmolars/?fref=ts" title="Visit Us on Facebook" target="_blank">Facebook</a> page to hear about the 2023 program!</h2>
            <br/>
            <br/>
            {/* <p>
              <strong>How to Apply:</strong>
            </p>
            <ul>
              <li>Watch a video about wisdom teeth extraction.</li>
              <li>Take a short quiz.</li>
              <li>Submit your application.</li>
            </ul>
            <h3>
              We wish every student the best as they pursue their academic
              endeavors.{" "}
            </h3>
            <div className="w4w-22__btn-group">
              <TheaterVideo
                videoUrl={`https://www.youtube.com/watch?v=${wisdomTeethData.youtube}`}
                controls={true}
                playing={true}
                onlyButton={true}
                buttonClass="w4w-22__btn"
              />
              <Button
                className="w4w-22__btn"
                buttonText="Take the Quiz"
                href="/w4w-step-1/"
              />
            </div> */}

            <div className="w4w-22__facts">
              <h3>Just the facts&hellip;</h3>
              <ul>
                <li>
                  Wisdom teeth that are impacted (they cannot erupt completely)
                  can cause problems in your mouth and sinus.
                </li>
                <li>
                  9 out of 10 people will have at least one wisdom tooth that is
                  impacted.
                </li>
                <li>Wisdom teeth are also known as third molars.</li>
                <li>
                  Wisdom teeth often appear between the ages of 17 and 25.
                </li>
                <li>
                  Impacted wisdom teeth can cause infections, damage to other
                  teeth, and cysts.
                </li>
              </ul>
            </div>
          </div>
          <img
            src={image("w4w-bottom-right-image")}
            className="w4w-22__apply-br w4w-22__shapes-img"
            alt="color shapes"
          />
        </div>
      </div>
    </Layout>
  )
}

export default W4W
